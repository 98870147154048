export const colors = {
    green: '#013952',
    gold: '#9E7C11',
    pearl: '#F5F5F5',
    darkGreen: '#012738',
    white: '#FFF',
    brown: '#5E3C0F',
    shade: 'rgba(0,0,0,0.3)',
    black: '#000',
    redBrown: '#9E3511',
    boxHighlight: 'rgba(255,255,255,0.2)',
    boxShadow: 'rgba(0,0,0,0.2)',
};
