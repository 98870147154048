import { createTheme } from '@mui/material/styles';
import { colors, fontFamily, fontSize } from '.';

export const theme = createTheme({
    palette: {
        primary: {
            main: colors.gold,
        },
        secondary: {
            main: colors.brown,
        },
        background: {
            default: colors.green,
        },
        text: {
            primary: colors.pearl,
        },
        info: {
            main: colors.redBrown,
        },
    },
    typography: {
        h1: {
            color: colors.pearl,
            fontSize: fontSize.title,
            fontFamily: fontFamily.heading,
        },
        body2: {
            fontFamily: fontFamily.description,
            color: colors.redBrown,
            fontSize: fontSize.list,
            margin: '0 !important',
        },
        subtitle2: {
            fontFamily: fontFamily.title,
            color: colors.pearl,
            fontSize: fontSize.subtitle,
        },
        button: {
            fontFamily: fontFamily.title,
        },
        body1: {
            fontFamily: fontFamily.description,
        },
    },
});
