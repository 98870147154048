import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { SxProps } from '@mui/material';

interface FlexBoxProps extends BoxProps {
    direction?: 'row' | 'column';
    justifyContent?: string;
    alignItems?: string;
    children?: React.ReactNode;
    sx?: SxProps;
}
export const FlexBox = ({
    direction = 'row',
    justifyContent = 'flex-start',
    alignItems = 'flex-start',
    children,
    sx,
    ...boxProperties
}: FlexBoxProps) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: direction,
            justifyContent,
            alignItems,
            ...sx,
        }}
        {...boxProperties}
    >
        {children}
    </Box>
);
