import React from 'react';
import { Outlet } from 'react-router-dom';
import { Footer } from 'components/common';
import { Box, Container } from '@mui/material';
import { flexContainerSx, pageContainerStyle } from 'styles/globalStyles';
import { useAuth } from 'context';

export const Layout = () => {
    const { isLoggedIn } = useAuth();
    return (
        <Box
            sx={{
                ...pageContainerStyle,
                ...flexContainerSx,
                alignItems: 'center',
            }}
        >
            <Container
                component='main'
                sx={{
                    paddingBottom: '3.5rem',
                    maxWidth: {
                        xs: '100%',
                        sm: '600px',
                        md: '768px',
                    },
                }}
            >
                <Outlet />
            </Container>
            {!!isLoggedIn && <Footer />}
        </Box>
    );
};
