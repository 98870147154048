import React from 'react';
import { Box, Typography } from '@mui/material';
import { flexBasicSx } from 'styles/globalStyles';
import { borderRadius, fontSize, colors } from 'theme';

const leftInfoBoxStyle = {
    ...flexBasicSx,
    width: '30%',
    border: `0.063rem solid ${colors.redBrown}`,
    borderRight: 'none',
    borderRadius: borderRadius.leftBottomRounded25,
    color: colors.redBrown,
    fontSize: fontSize.subtitle,
    alignItems: 'center',
};

export const LeftInfoBox = ({ text }: { text: string }) => (
    <Box sx={leftInfoBoxStyle}>
        <Typography align='left' ml='0.625rem'>
            {text}
        </Typography>
    </Box>
);
