import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { MouseEventHandler } from 'react';

interface IButton extends ButtonProps {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    children: string | React.ReactNode;
}

export const PrimaryButton: React.FC<IButton> = ({
    onClick,
    children,
    ...buttonProps
}) => (
    <Button
        variant='contained'
        color='primary'
        onClick={onClick}
        {...buttonProps}
    >
        {children}
    </Button>
);
