import { Ingredient } from 'interfaces';
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IngredientContextType {
    showIngredientModal: boolean;
    selectedIngredients: Ingredient | null;
    setShowIngredientModal: (show: boolean) => void;
    setSelectedIngredients: (ingredients: Ingredient | null) => void; // Replace 'any' with the actual type
}

const IngredientContext = createContext<IngredientContextType | undefined>(
    undefined
);

// Create a provider component
export const IngredientProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [showIngredientModal, setShowIngredientModal] = useState(false);
    const [selectedIngredients, setSelectedIngredients] =
        useState<Ingredient | null>(null);

    return (
        <IngredientContext.Provider
            value={{
                showIngredientModal,
                setShowIngredientModal,
                selectedIngredients,
                setSelectedIngredients,
            }}
        >
            {children}
        </IngredientContext.Provider>
    );
};

export const useIngredientContext = (): IngredientContextType => {
    const context = useContext(IngredientContext);
    if (context === undefined) {
        throw new Error(
            'useIngredientContext must be used within an IngredientProvider'
        );
    }
    return context;
};
