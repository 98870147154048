import React from 'react';
import { Helmet } from 'react-helmet';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SnackbarProvider } from 'notistack';

import { theme } from './theme';
import { globalStyles } from './styles';
import { AuthProvider } from 'context/AuthContext';
import { CustomizedRouteProvider } from 'routes';

export const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 1,
                staleTime: 300000,
            },
        },
    });
    return (
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        autoHideDuration={3000}
                    >
                        <Helmet>
                            <title>Cocktail Cabinet</title>
                            <link
                                rel='stylesheet'
                                href='https://use.typekit.net/xug3ivb.css'
                            />
                        </Helmet>
                        <GlobalStyles styles={globalStyles} />
                        <CustomizedRouteProvider />
                    </SnackbarProvider>
                </ThemeProvider>
            </QueryClientProvider>
        </AuthProvider>
    );
};
