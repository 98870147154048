import { basicLinkStyle } from 'styles/globalStyles';
import { borderRadius, colors } from '../../../../src/theme';

export const appBarStyle = {
    border: `0.063rem solid ${colors.gold}`,
    borderRadius: borderRadius.footerRadius,
    maxWidth: 'calc(100% - 20px)',
    margin: '0 auto',
};

export const toolbarStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    color: colors.gold,
    height: '4rem',
};

export const footerLinkStyle = {
    ...basicLinkStyle,
    color: colors.gold,
};

export const footerStyle = {
    position: 'fixed',
    bottom: '0',
    maxWidth: {
        xs: '100%',
        sm: '36.25rem',
        md: '46.75rem',
    },
};
