export const fontFamily = {
    title: 'p22-arts-and-crafts-tall, sans-serif',
    description: 'p22-fllw-eaglefeather, sans-serif',
    heading: 'p22-flw-exhibition, sans-serif',
};

export const fontSize = {
    title: '1.875rem',
    description: '1rem',
    filter: '1.875rem',
    subtitle: '1.25rem',
    list: '0.75rem',
};
