import React from 'react';
import { Skeleton } from '@mui/material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { getRoutes } from 'utils/protectedRoutes';

export const CustomizedRouteProvider = () => {
    const { isLoggedIn } = useAuth();
    const routes = getRoutes(isLoggedIn);
    const router = createBrowserRouter(routes);
    return <RouterProvider router={router} fallbackElement={<Skeleton />} />;
};
