import React, { lazy, Suspense } from 'react';
import { Layout } from 'components/Layout';
import { Spinner } from 'components/common';

// Lazy loading components
const AuthPage = lazy(() =>
    import('components/AuthPage').then((module) => ({
        default: module.AuthPage,
    }))
);
const LandingPage = lazy(() =>
    import('components/LandingPage').then((module) => ({
        default: module.LandingPage,
    }))
);
const RecipePage = lazy(() =>
    import('components/RecipePage').then((module) => ({
        default: module.RecipePage,
    }))
);

export const getRoutes = (isLoggedIn: boolean) => [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                index: true,
                element: (
                    <Suspense fallback={<Spinner />}>
                        {isLoggedIn ? <LandingPage /> : <AuthPage />}
                    </Suspense>
                ),
            },
            {
                path: 'recipe/:recipeId',
                element: (
                    <Suspense fallback={<Spinner />}>
                        {isLoggedIn ? <RecipePage /> : <AuthPage />}
                    </Suspense>
                ),
            },
            {
                path: 'auth',
                element: (
                    <Suspense fallback={<Spinner />}>
                        <AuthPage />
                    </Suspense>
                ),
            },
        ],
    },
];
