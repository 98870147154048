import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const Spinner = () => {
    const spinnerStyle = { margin: 'auto' };
    const spinnerContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    };
    return (
        <Box sx={spinnerContainerStyle}>
            <CircularProgress sx={spinnerStyle} size={60} />
        </Box>
    );
};
