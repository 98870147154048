import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    ReactNode,
} from 'react';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [accessToken, setAccessTokenState] = useState<string | null>(null);
    const [expiresIn, setExpiresInState] = useState<string | null>(null);

    useEffect(() => {
        const storedAccessToken = sessionStorage.getItem('accessToken');
        const storedExpiresIn = sessionStorage.getItem('expiresIn');
        const isLoginExpired = storedExpiresIn
            ? new Date() >= new Date(storedExpiresIn)
            : false;
        setIsLoggedIn(!!storedAccessToken && !isLoginExpired);

        // Set state based on the session storage
        if (storedAccessToken && !isLoginExpired) {
            setAccessTokenState(storedAccessToken);
            setExpiresInState(storedExpiresIn);
        }
    }, [accessToken, expiresIn]);

    const setAccessToken = (token: string | null) => {
        setAccessTokenState(token);
        if (token === null) {
            sessionStorage.removeItem('accessToken');
        } else {
            sessionStorage.setItem('accessToken', token);
        }
    };

    const setExpiresIn = (time: string | null) => {
        setExpiresInState(time);
        if (time === null) {
            sessionStorage.removeItem('expiresIn');
        } else {
            sessionStorage.setItem('expiresIn', time);
        }
    };

    const logout = () => {
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('expiresIn');
        setIsLoggedIn(false);
    };

    return (
        <AuthContext.Provider
            value={{
                logout,
                isLoggedIn,
                setIsLoggedIn,
                accessToken,
                setAccessToken,
                expiresIn,
                setExpiresIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

interface AuthContextType {
    isLoggedIn: boolean;
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
    accessToken: string | null;
    setAccessToken: (token: string | null) => void;
    expiresIn: string | null;
    setExpiresIn: (time: string | null) => void;
    logout: () => void;
}

export default AuthContext;
