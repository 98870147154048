import React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';

interface CustomAlertProps extends AlertProps {
    id: string;
    message: string;
}

export const CustomAlert = React.forwardRef<HTMLDivElement, CustomAlertProps>(
    (props, ref) => {
        const { id, message, ...otherProps } = props;
        return (
            <Alert ref={ref} {...otherProps} id={id}>
                {message}
            </Alert>
        );
    }
);

CustomAlert.displayName = 'CustomAlert';
