import React from 'react';
import { AlertColor } from '@mui/material';
import { CustomAlert } from 'components/common/CustomAlert';
import {
    VariantType,
    enqueueSnackbar,
    SnackbarKey,
    SnackbarMessage,
} from 'notistack';

export const handleNotification = (variant: VariantType, message: string) => {
    enqueueSnackbar(message, {
        content: (key: SnackbarKey, message?: SnackbarMessage) => (
            <CustomAlert
                id={key as string}
                message={message as string}
                severity={variant as AlertColor}
            />
        ),
    });
};
