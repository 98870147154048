export const borderRadius = {
    leftBottomRounded: '0 0 0 18px',
    rightRounded: '0 20px 20px 0',
    rightRounded10: '0 10px 10px 0',
    leftBottomRounded25: '0 0 0 25px',
    rightBottomRounded25: '0 0 25px 0',
    menuBarRadius: '15px',
    menuButtonRadius: '45px',
    menuFlyoutRadius: '15px',
    pageTitleRadius: '0 0 30px 30px',
    favListRadius: '0 40px 40px 0',
    footerRadius: '65px 65px 30px 30px',
};
