import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors, fontFamily, fontSize } from '../theme';

export const globalStyles = `
  body {
    font-family: ${fontFamily.description};
    font-size: ${fontSize.description};
    background-color: ${colors.green};
  }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
`;

export const useFormStyles = makeStyles({
    formContainer: {
        backgroundColor: '#f0f0f0',
        padding: '20px',
        borderRadius: '8px',
    },
});

export const flexBasicSx = {
    display: 'flex',
};

export const flexContainerSx = {
    ...flexBasicSx,
    flexDirection: 'column',
};

export const flexRowContainerSx = {
    ...flexBasicSx,
    flexDirection: 'row',
    alignItems: 'center',
};

export const basicLinkStyle = {
    textDecoration: 'none',
};

export const pageContainerStyle = {
    maxWidth: {
        xs: '100%',
        sm: '37.5rem',
        md: '48rem',
    },
    margin: '0 auto',
    padding: '2.5rem 0.625rem',
};

export const profileImageStyle = {
    height: '9.25rem',
    width: '9.25rem',
    margin: '1.25rem auto 0.75rem',
};

export const infoSectionContainerStyle = {
    ...flexContainerSx,
    width: 'calc(100% - 1.25rem)',
    backgroundColor: colors.white,
    boxShadow: `inset 0 0 0 0.625rem ${colors.brown}`,
    padding: '1.25rem 0.625rem 0.625rem 0.625rem',
};

export const sectionWrapperStyle = {
    padding: '0.625rem 0.625rem 0',
};
