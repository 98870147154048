import React from 'react';
import { Box, Typography } from '@mui/material';
import { borderRadius, colors } from 'theme';

const rightInfoBoxStyle = {
    width: '70%',
    border: `0.63rem solid ${colors.redBrown}`,
    borderRadius: borderRadius.rightRounded,
    backgroundColor: colors.redBrown,
    color: colors.pearl,
    fontSize: '1.5rem',
};

export const RightInfoBox = ({
    text,
    list,
}: {
    text?: string;
    list?: string[];
}) => (
    <Box sx={rightInfoBoxStyle}>
        {text && <Typography align='left'>{text}</Typography>}
        {list &&
            list.map((text) => (
                <Typography align='left' key={text}>
                    {text}
                </Typography>
            ))}
    </Box>
);
