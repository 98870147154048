import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LiquorIcon from '@mui/icons-material/Liquor';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
    AppBar,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Toolbar,
} from '@mui/material';

import { useAuth } from 'context';
import { colors, fontFamily } from 'theme';
import { handleNotification } from 'utils/handleNotification';
import { appBarStyle, footerStyle, toolbarStyle } from './Footer.styled';

export const Footer = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const handleLogout = () => {
        logout();
        navigate('./auth');
    };

    const handleClickOnWIPFeature = () => {
        handleNotification('info', 'New feature is on the way!');
    };

    return (
        <Container fixed sx={footerStyle}>
            <AppBar position='static' color='secondary' sx={appBarStyle}>
                <Toolbar sx={toolbarStyle}>
                    <LiquorIcon
                        fontSize='large'
                        onClick={handleClickOnWIPFeature}
                    />
                    <FavoriteBorderIcon
                        fontSize='large'
                        onClick={handleClickOnWIPFeature}
                    />
                    <LogoutIcon
                        fontSize='large'
                        onClick={() => setOpenLogoutDialog(true)}
                    />
                </Toolbar>
            </AppBar>
            <Dialog
                open={openLogoutDialog}
                onClose={() => setOpenLogoutDialog(false)}
                aria-labelledby='logout-dialog-title'
            >
                <DialogTitle
                    id='logout-dialog-title'
                    sx={{
                        fontFamily: fontFamily.heading,
                        color: colors.redBrown,
                    }}
                >
                    Confirm Logout
                </DialogTitle>
                <DialogContent sx={{ color: colors.redBrown }}>
                    Are you sure you want to logout?
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpenLogoutDialog(false)}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleLogout} color='primary'>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};
