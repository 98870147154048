import React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

interface IBackButton extends IconButtonProps {
    onClick?: () => void;
}

const BackButton = ({ onClick, ...iconButtonProps }: IBackButton) => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <IconButton {...iconButtonProps} onClick={onClick || handleBack}>
            <ArrowBackIcon />
        </IconButton>
    );
};

export default BackButton;
