import React from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface ICloseButton extends IconButtonProps {
    onClick: () => void;
}

export const CloseButton = ({ onClick, ...iconButtonProps }: ICloseButton) => (
    <IconButton {...iconButtonProps} onClick={onClick}>
        <HighlightOffIcon />
    </IconButton>
);
