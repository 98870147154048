import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { SxProps } from '@mui/system';

interface LazyAvatarProps {
    src?: string;
    alt: string;
    sx?: SxProps;
}

export const LazyAvatar: React.FC<LazyAvatarProps> = ({
    src,
    alt,
    sx,
    ...otherProps
}) => {
    const [imgLoadError, setImgLoadError] = useState(false);

    const handleImgError = () => setImgLoadError(true);

    return (
        <Avatar sx={sx} {...otherProps}>
            {!imgLoadError ? (
                <LazyLoadImage
                    alt={alt}
                    src={src}
                    effect='blur'
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    onError={handleImgError}
                />
            ) : (
                alt.charAt(0).toUpperCase()
            )}
        </Avatar>
    );
};
